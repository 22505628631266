import React, { useContext, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { GlobalDataContext } from "../../context/context";
import { BiChevronRightCircle, BiPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import SocialMedia from "../global/SocialMedia";
import TransparentHeader from "../global/TransparentHeader";
import { Link } from "react-router-dom";
import BaseLayout from "../global/BaseLayout";


const dbBlog = [
    {
        title: 'Benefits Of Hiring Professional Construction Services',
        description: [
            {
                subtitle: '',
                parrafo: 'Are you planning to renovate your house or start a new construction project? If yes, then you need to consider hiring professional construction services. Professional construction services can provide you with numerous benefits and in this blog, we will discuss some of them.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A40%3A03.197Z-2.672.jpg?alt=media&token=d379d585-ffc1-4cc2-9e59-1467c11aa9fb',
                steps: [
                    {
                        title: 'Quality Work',
                        info: 'Professional construction services have skilled and experienced workers who can provide you with high-quality work. They have the knowledge and tools required to complete the job effectively and efficiently.',
                    },
                    {
                        title: 'Time-Saving',
                        info: 'Professional construction services can save you time by completing the job within the given deadline. They have the manpower and resources to work on your project and complete it in a timely manner.',
                    },
                    {
                        title: 'Cost-Effective',
                        info: 'Hiring professional construction services can be cost-effective in the long run. They can help you save money by preventing costly mistakes and providing you with quality work that will last for years to come.',
                    },
                    {
                        title: 'Safety',
                        info: 'Professional construction services prioritize safety and ensure that all safety protocols are followed during the project. This can prevent accidents and injuries on the job site.',
                    },
                    {
                        title: 'Access To Resources',
                        info: 'Professional construction services have access to the latest technology, equipment, and materials required for the job. This can help them provide you with quality work and complete the job efficiently.',
                    },
                ],
            },
            {
                subtitle: '',
                parrafo: 'Hiring professional construction services can provide you with numerous benefits. They can provide you with quality work, save you time and money, ensure safety on the job site, and comply with all legal requirements. If you are looking for reliable and professional construction services, contact us at JA Home Improvement Inc today. We would be happy to provide you with our services and help you achieve your construction goals.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A30%3A18.944Z-8.jpg?alt=media&token=a89060de-b2ac-4231-8455-d2e774f4050f',
                steps: [],

            },

        ],

    },
    {
        title: 'When Do You Need An Affordable Construction Service?',
        description: [
            {
                subtitle: '',
                parrafo: "Construction projects can be costly, and it's important to find a construction service that fits within your budget. However, affordability should not come at the expense of quality. In this blog you learn, we will explore when you may need an affordable construction service and how to ensure that you are still getting high-quality work. The following are some of the points that you should consider when hiring a construction service.",
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A09%3A52.424Z-19.jpg?alt=media&token=cd8e9b34-a434-45b9-966a-03e53b814eb7',
                steps: [
                    {
                        title: 'Small Projects',
                        info: 'If you have a small construction project, such as a home renovation or a landscaping project, you may not need a large construction service with a big budget. An affordable construction service can provide you with the expertise and resources you need to complete the project at a lower cost.',
                    },
                    {
                        title: 'Tight Budgets',
                        info: "If you have a tight budget for your construction project an affordable construction service can help you stay within your budget while still providing high-quality work. It's important to choose a construction service that is transparent about their pricing and can provide you with a detailed quote before starting the project.",
                    },
                ],
            },
        ],
    },
    {
        title: 'Qualities Of A Reliable Construction Contractor',
        description: [
            {
                subtitle: '',
                parrafo: "Choosing the right construction contractor is essential for any successful construction project. A reliable construction contractor can ensure that the project is completed on time, within budget and to the highest standards. If you want to know more about affordable and reliable contractors, here are the three things you need to check before hiring them:",
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fstock%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A09%3A55.330Z-5.jpg?alt=media&token=996e59d2-55f3-46a6-9d08-a400a477d46d',
                steps: [
                    {
                        title: 'Experience And Expertise',
                        info: 'A reliable construction contractor should have years of experience in the industry and possess the necessary expertise to handle the project. Look for a contractor with a track record of successful projects in the specific type of construction work you need.',
                    },
                    {
                        title: 'Transparency And Communication',
                        info: "A reliable construction contractor should be transparent about their pricing and provide you with a detailed quote before starting the project. They should also prioritize communication throughout the project to keep you updated on progress and any changes that need to be made.",
                    },
                    {
                        title: 'Attention To Detail',
                        info: "A reliable construction contractor should have a keen eye for detail and strive for perfection in their work. They should be able to identify potential issues before they become bigger problems and ensure that the project is completed to the highest standards.",
                    },
                    {
                        title: 'Professionalism And Ethics',
                        info: "A reliable construction contractor should operate with professionalism and adhere to high ethical standards. Look for JA Home Improvement Inc who treats their employees and subcontractors fairly.",
                    },
                ],
            },
            {
                subtitle: '',
                parrafo: 'Choosing a reliable construction contractor is essential for any successful construction project. Look for a contractor with experience and expertise, who is transparent and communicates effectively, who has a keen eye for detail, who is reliable and timely and who operates with professionalism and ethics. Contact us today to learn more about our construction services and how we can help you achieve your goals with a reliable and trustworthy team.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-02-17T15%3A10%3A14.739Z-7.jpeg?alt=media&token=08399f89-4b3b-4ea4-9cb2-48ed5aa5702b',
                steps: [],
            },
        ],
    },
]


function CardBlog() {
    const { rpdata } = useContext(GlobalDataContext);
    const [itemMenu, setItemMenu] = useState('Benefits Of Hiring Professional Construction Services')


    return (
        <BaseLayout PageName="Blog">
            <div className="w-full h-auto">
                <TransparentHeader
                    headertitle={itemMenu}
                    Subheader="Blog"
                    bgimg={`${rpdata?.gallery?.[10]}`}
                />
                <div className="w-[90%] mx-auto pt-10 pb-0 md:py-10 flex flex-col md:flex-row">
                    <div className="md:w-[30%] w-full h-auto px-5 md:px-10 py-12 border bg1 rounded-2xl">
                        <h2 className="text-[40px] pb-7 text-white">Our Blogs</h2>
                        <ul className="space-y-2">
                            {
                                dbBlog.map((item, index) => {
                                    return (
                                        <li key={index} className="flex gap-3 pb-5 items-center cursor-pointer text-white font-semibold hover:font-extrabold duration-300 ease-in-out" onClick={() => setItemMenu(item.title)}>
                                            <FiChevronRight />

                                            <Link
                                                to={{
                                                    pathname: `/blog/${item.title.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`,

                                                }}
                                            >
                                                <span>{item.title}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="pb-5">
                            <h4 className="text-[40px] pb-1 text-white">Contact</h4>
                            <ul className="px-1 md:px-4">
                                {
                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                        return (
                                            <li key={index} className='flex items-center text-white pb-3'>
                                                <a
                                                    href={`tel:+1${phone.phone}`}
                                                    className='flex items-center'
                                                >
                                                    <BiPhoneCall />
                                                    <span className="pl-2">
                                                        {phone.phone}
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                                {
                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className='flex items-center text-white pb-3'
                                            >
                                                <a
                                                    href={`mailto:${email.email}`}
                                                    className='flex items-center'
                                                >
                                                    <MdOutlineEmail />
                                                    <span className="pl-2">
                                                        {email.email}
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {
                            rpdata?.dbSocialMedia?.redes?.length > 1 ?
                                <div className="pb-5 text-center md:text-start">
                                    <h4 className="text-[40px] text-white" >Follow Us</h4>
                                    <SocialMedia />
                                </div>
                                : null
                        }
                    </div>
                    <div className="md:w-[70%] w-full h-auto md:px-10 px-5 py-8">
                        {
                            dbBlog.map((item, index) => {
                                switch (itemMenu) {
                                    case item.title:
                                        return (
                                            <div key={index}>
                                                <h2 className="text-[40px] px-0 md:px-[10%] text-center text-[#2E8389]">{item.title}</h2>
                                                <div>
                                                    {
                                                        item.description.map((desp, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <img
                                                                        className="w-full h-[400px] object-cover rounded-2xl my-5"
                                                                        src={
                                                                            desp.imgDes
                                                                        } alt="not-found" />
                                                                    <p className="pb-5">{desp.parrafo}</p>
                                                                    <ul>
                                                                        {
                                                                            desp.steps ?
                                                                                desp.steps.map((step, index) => {
                                                                                    return (
                                                                                        <li key={index}>
                                                                                            <div className="flex gap-2 items-center">
                                                                                                <p>
                                                                                                    <span className="text-[20px] text-[#2E8389] font-extrabold"><BiChevronRightCircle />
                                                                                                    </span>
                                                                                                </p>
                                                                                                <p className="font-bold capitalize">{step.title}</p>
                                                                                            </div>
                                                                                            <p className="px-7">{step.info}</p>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                                :
                                                                                null
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )

                                    default:
                                        return null
                                }

                            })
                        }
                    </div>
                </div>
            </div>
        </BaseLayout>

    );
}

export default CardBlog;