import React, { useContext } from 'react'
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { GlobalDataContext } from '../../context/context';


const dbBlog = [
    {
        title: 'Benefits Of Hiring Professional Construction Services',
        description: [
            {
                subtitle: '',
                parrafo: 'Are you planning to renovate your house or start a new construction project? If yes, then you need to consider hiring professional construction services. Professional construction services can provide you with numerous benefits and in this blog, we will discuss some of them.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A40%3A03.197Z-2.672.jpg?alt=media&token=d379d585-ffc1-4cc2-9e59-1467c11aa9fb',
            },

        ],

    },
    {
        title: 'When Do You Need An Affordable Construction Service?',
        description: [
            {
                subtitle: 'ffff',
                parrafo: "Construction projects can be costly, and it's important to find a construction service that fits within your budget. However, affordability should not come at the expense of quality. In this blog you learn, we will explore when you may need an affordable construction service and how to ensure that you are still getting high-quality work. The following are some of the points that you should consider when hiring a construction service.",
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fgallery%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A09%3A52.424Z-19.jpg?alt=media&token=cd8e9b34-a434-45b9-966a-03e53b814eb7',
            },
        ],
    },
    {
        title: 'Qualities Of A Reliable Construction Contractor',
        description: [
            {
                subtitle: 'ffff',
                parrafo: "Choosing the right construction contractor is essential for any successful construction project. A reliable construction contractor can ensure that the project is completed on time, within budget and to the highest standards. If you want to know more about affordable and reliable contractors, here are the three things you need to check before hiring them:",
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63cebc81f98562e4ddecd703%2Fstock%2FJA%20Home%20Improvement%20Inc-2023-01-23T19%3A09%3A55.330Z-5.jpg?alt=media&token=996e59d2-55f3-46a6-9d08-a400a477d46d',
            },
        ],
    },
]



const CardNavblog = () => {

    const { rpdata } = useContext(GlobalDataContext)
    return (
        <div className='w-full h-auto py-5 flex flex-col md:flex-row justify-center items-start px-5 gap-4 '>
            <div className='md:w-[25%] w-full bg1 mt-5 h-[500px] text-white px-5 rounded-2xl py-10'>
                <h3 className='text-[28px] font-extrabold'>Recent News</h3>
                <div className='w-full h-auto px-8 flex items-center space-x-2 pb-4'>
                    <BsFillArrowRightCircleFill />
                    <Link
                        to={'/reviews'}
                        className='text-[16px] font-semibold'
                    >
                        Reviews
                    </Link>

                </div>
                <div className='w-full h-auto px-8 flex items-center space-x-2 pb-4'>
                    <BsFillArrowRightCircleFill />
                    <Link
                        to={'/gallery'}
                        className='text-[16px] font-semibold'
                    >
                        New Projects
                    </Link>

                </div>
                <div className='w-full h-auto px-8 flex items-center space-x-2 pb-4'>
                    <BsFillArrowRightCircleFill />
                    <Link
                        to={'/contact'}
                        className='text-[16px] font-semibold'
                    >
                        Contact US
                    </Link>

                </div>

                <h3 className='text-[26px] font-extrabold py-8'>Want To Try Our Sevices?</h3>
                <div className='w-full h-auto px-8 flex items-center space-x-2 pb-4'>
                    <FiPhoneCall fontSize={28} />
                    <a
                        href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                    >
                        <h5 className='text-[21px]'>
                            {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                        </h5>
                    </a>

                </div>
            </div>
            <div className='flex flex-wrap items-center justify-start gap-4 w-full md:w-[60%] h-auto p-5'>
                {
                    dbBlog.map((item, index) => {
                        return (
                            <div key={index} className='w-full md:w-[45%] h-auto py-3 px-1 rounded-2xl bg-white shadow-2xl'>
                                {
                                    item.description.map((desp, index) => {
                                        return (
                                            <div key={index} className='w-full h-[60%] p-3'>
                                                <img
                                                    src={desp.imgDes}
                                                    alt='no found'
                                                    loading='lazy'
                                                    className='w-full h-full md:h-full object-cover rounded-2xl'
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    item.description.map((info, index) => {
                                        return (
                                            <section key={index} className='w-full h-auto px-8 '>
                                                <h5 className='font-extrabold text-[18px]'>{item.title}</h5>
                                                <p className='pb-1'>{info.parrafo.substring(0, 103) + "..."}</p>

                                            </section>
                                        );
                                    })
                                }
                                <div className='w-full h-auto px-8 py-4'>
                                    <Link
                                        to={{
                                            pathname: `/blog/${item.title.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`,

                                        }}
                                        className='border-[2px] borderColor px-2 py-1 rounded-full'
                                    >
                                        Read More
                                    </Link>
                                    
                                </div>

                            </div>
                        );
                    })
                }
            </div>
        </div >
    )
}
export default CardNavblog